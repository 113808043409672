/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function AssetFAQ({ creatorId }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
    console.log('toggle answer');
    console.log(e);
    console.log(id);
  };

  const faq = [
    {
      id: 1,
      title: 'What is a digital asset?',
      answer: 'A digital asset is a one-of-a-kind ownable asset that you can purchase. When you purchase one of these asets, you are the official owner of an original ' + process.env.SITE_NAME + ' digital asset that provides you some serious real-world benefits.',
    },
    {
      id: 2,
      title: 'How do I purchase this digital asset?',
      answer: 'When the campaign goes live, simply click buy-now and purchase with your credit card.<br /><br />Through this process you will be prompted to create an account if you do not have one already.  This is to ensure you can view and transfer your digigal asset in the future. ',
    },
    {
      id: 3,
      title: 'Do I need cryptocurrency to buy a ' + process.env.SITE_NAME + ' digital asset?',
      answer: 'Not at all. You can pay using your credit card.',
    },
    {
      id: 4,
      title: 'What happens when I buy one of the digital assets?',
      answer: 'We will be minting the digital assets, which is a crypto term meaning that your digital asset will become a part of the blockchain – the crypto world where digital assets are recorded.',
    },
    {
      id: 5,
      title: 'What happens if I don’t have a Crypto Wallet?',
      answer: 'Not to worry, you do not need a crypto wallet to purchase this ' + process.env.SITE_NAME + ' digital asset.<br /><br />We are here to make things easier, transferring your digital asset can be done any time with a few simple clicks.',
    },
    {
      id: 6,
      title: 'Is there support available if I need help?',
      answer: 'Yes, absolutely! We are here to help every step of the way. <br /><br />At any time, if you have any questions or need any support, please email us and we will be happy to help&nbsp;you:<br /><a href="mailto:"' + process.env.CLIENT_EMAIL + '">' + process.env.CLIENT_EMAIL + '</a><br /><a href=' + process.env.CLIENT_WEBSITE + ' target="_blank" rel="noreferrer">' + process.env.CLIENT_WEBSITE + '</a>',
    },
  ];

  useEffect(() => {
    setQuestions(faq);
  }, []);

  return (
    <div className="asset-faq">
      <h2 className="digital-asset__subheader">Frequently Asked Questions</h2>
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item"
          onClick={(e) => toggleAnswer(faq.id, e)}>
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#">
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#">
                +
              </a>
            )}
          <div className="asset-faq__item--question">{faq.title}</div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default AssetFAQ;
