import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment-mini';
import { check } from 'prettier';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import SimpleModal from '../modal/modal';
import { getBlockchainType, getParameterByName } from '../../managers/Helpers';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

const EDropLanding = ({ transferToken }) => {
  const [transferCode, setTransferCode] = useState(null);
  const [verifiedCode, setVerifiedCode] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorData, setErrorData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const optOut = () => {
    sessionStorage.removeItem(transferToken);
    window.location.href = '/';
  };
  const handleInputChange = (e) => {
    setTransferCode(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const clearAllTransferCookies = () => {
    Object.entries(sessionStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('NFT_TRANSFER')) {
        sessionStorage.removeItem(key);
      }
      return true;
    });
  };

  const prePopulateRegData = () => {
    const registerObj = {};
    
    if (getParameterByName('f')) {
      registerObj.firstName = getParameterByName('f');
    }
    if (getParameterByName('l')) {
      registerObj.lastName = getParameterByName('l');
    }
    if (getParameterByName('e')) {
      registerObj.email = getParameterByName('e');
    }

    if (registerObj.firstName || registerObj.lastName || registerObj.email) {
      sessionStorage.setItem('ETHOS_REGISTRATION', JSON.stringify(registerObj));
    }
  };

  const getTransferCodePreview = async (previewCode) => {
    setIsLoading(true);
    let alreadyClaimed = false;
    if (localStorage.getItem('NFT_TOKEN_TRANSFERRED_' + previewCode)) {
      alreadyClaimed = true;
    }
    if (!alreadyClaimed) {
      const preview = await AccountManager.getTransferDetails(null, previewCode);
      if (preview && preview.success === false) {
        setPreviewData(null);
        setErrorData(preview);
      } else if (preview) {
        setPreviewData(preview);
        setVerifiedCode(previewCode);
        sessionStorage.setItem('NFT_TRANSFER_' + previewCode, previewCode);
        // check for pre pop details
        prePopulateRegData();
      } else {
        toast.error('Transfer code not found. Please check your code and try again.');
        setPreviewData(null);
        clearAllTransferCookies();
      }
    } else {
      toast.error('This NFT token has already been claimed.');
      setPreviewData(null);
      clearAllTransferCookies();
    }
    setIsLoading(false);
  };

  const enterCode = async () => {
    // check if the code exists
    await getTransferCodePreview(transferCode);
  };

  const claimNow = (e) => {
    e.preventDefault();
    setShowModal(true);
    setEmailExists(false);
  };

  const createUnverifiedAccount = async () => {
    const payload = {
      email: emailAddress,
      creatorId: process.env.CREATOR_ID,
    };
    const createAccount = await AccountManager.createUnverifiedAccount(payload);
    if (createAccount && createAccount.token) {
      // authenticate the user with the token and redirect them to the collections page
      AccountManager.setLoginState(createAccount.token);
      window.location.href = '/account/collection';
    } else {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  const checkEmailAddress = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e) {
      e.preventDefault();
    }
    if (!emailPattern.test(emailAddress)) {
      toast.error('Please enter a valid email address.');
      setIsLoading(false);
    } else if (!agreeTerms) {
      toast.error('Please agree to the terms and conditions.');
      setIsLoading(false);
    } else {
      setShowModal(true);
      setEmailExists(false);
      setIsLoading(true);
      const checkEmail = await AccountManager.emailSearch(null, emailAddress);
      if (checkEmail && checkEmail.emailExists === true) {
        setEmailExists(true);
        setIsLoading(false);
      } else {
        await createUnverifiedAccount();
      }
    }
  };

  const onEnterKey = (e) => {
    if (e.keyCode === 13) {
      checkEmailAddress();
    }
  };

  const handleCheckboxChange = (e) => {
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    setAgreeTerms(isChecked);
  };

  useEffect(() => {
    document.body.classList.add('-account-background');
    document.body.style.backgroundImage = 'url(' + process.env.ACCOUNT_BACKGROUND + ')';
  }, []);

  useEffect(() => {
    setTransferCode(transferToken);
    if (transferToken) {
      getTransferCodePreview(transferToken);
    } else {
      setIsLoading(false);
    }
  }, [transferToken]);

  return (
    <div className="account-container__outer">
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        {isLoading
          && <Throbber throbberText="Claiming..." />}
        {!isLoading
        && (
        <div>
          {emailExists
            ? (
              <div>
                <div className="account-subheading -slim-padding">An account with this email already exists. Login in now to claim this digital asset</div>
                <div className="form-cta">
                  <Link
                  to="/login"
                  className="button">
                    Log in
                  </Link>
                </div>
              </div>
            )
            : (
              <div>Loader</div>
            )}
        </div>
        )}
      </SimpleModal>
      <div className="account-container__inner">
        <div className="account-box -center">
          {previewData && previewData.asset
            ? (
              <div className="account-heading">
                {process.env.CUSTOM_EDROP_TITLE
                  ? <div>{process.env.CUSTOM_EDROP_TITLE}</div>
                  : (
                    <div>
                      Claim your&nbsp;
                      {previewData
                        ? <span>{previewData.assetVariation && previewData.assetVariation.variationName ? previewData.assetVariation.variationName : previewData.asset.name}</span>
                        : <span>digital asset</span>}
                    </div>
                  )}
              </div>
            )
            : (
              <div className="account-heading">
                {process.env.CUSTOM_EDROP_TITLE || 'Claim your digital asset'}
              </div>
            )}
          {!errorData
          && (
          <div className="account-subheading">
            {process.env.CUSTOM_EDROP_SUBTITLE
              ? <>{process.env.CUSTOM_EDROP_SUBTITLE}</>
              : <>All that is needed is your email to claim.</>}
            
          </div>
          )}
          {errorData ? (
            <div>
              <div className="account-subheading">
                {errorData.message}
              </div>
            </div>
          )
            : (
              <div className="account-verify-token">
                {!verifiedCode
                  ? (
                    <div className="account-verify-form">
                      <input className="input-verify-token" placeholder="Enter transfer code" value={transferCode} onChange={(e) => handleInputChange(e)} />
                      <div className="account-verify-cta">
                        <button
                        onClick={() => enterCode()}
                        type="button">
                          Enter code
                        </button>
                      </div>
                    </div>
                  )
                  : (
                    <div>
                      <div className="input-code-verified">
                        Claim code verified
                      </div>
                      <img className="account-verify-token__check" src="https://ipfs.ethosnft.com/ethos/icon-success.svg" alt="" />
                    </div>
                  )}
              </div>
            )}
          <div className={isLoading ? 'edrop-preview' : 'edrop-no-preview'}>
            {isLoading
              && <Throbber throbberText="Loading preview..." />}
            {previewData
            && (
              <div>
                {previewData && previewData.asset
                  ? (
                    <div className="transfer-preview">
                      <div className="transfer-preview__image">
                        {previewData.assetVariation.mediaFileUrl.includes('.mp4')
                          ? (
                            <video autoPlay="autoplay" playsInline loop muted poster={previewData.assetVariation.thumbnailUrl}>
                              <source src={previewData.assetVariation.mediaFileUrl} type="video/mp4" />
                              <track
                                  default
                                  kind="captions"
                                  srcLang="en"
                                  src="" />
                              Your browser does not support the video tag.
                            </video>
                          )
                          : <img src={previewData.assetVariation && previewData.assetVariation.thumbnailUrl ? previewData.assetVariation.thumbnailUrl : previewData.asset.mediaLocalIPFS} alt="NFT Preview" />}
                      </div>
                      <div className="transfer-preview__details">
                        <div className="transfer-preview__details--inner">
                          <h3 id="edropName" className="card-title">{previewData.assetVariation && previewData.assetVariation.variationName ? previewData.assetVariation.variationName : previewData.asset.name}</h3>
                          {/* <p>
                            Blockchain:
                            {' '}
                            {getBlockchainType(previewData.asset.blockchain)}
                          </p> */}
                          <p>
                            Date created:
                            {' '}
                            {moment(previewData.asset.dateCreated).format('MMMM DD, YYYY')}
                          </p>
                          {previewData.asset.assetDescription !== 'NULL' ? (
                            <p>
                              {previewData.asset.assetDescription}
                            </p>
                          )
                            : <p dangerouslySetInnerHTML={{ __html: previewData.asset.assetHTMLDesc }}></p>}

                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div className="transfer-preview -no-flex">
                      <div>
                        {previewData.senderFirstName}
                        {' '}
                        {' '}
                        {' '}
                        {previewData.senderLastName}
                        {' '}
                        has sent you a
                        {' '}
                        {process.env.DIGITAL_ASSET_TYPE || 'digital asset'}
                        .
                      </div>
                      <blockquote className="form-blockquote__block">
                        {previewData.message}
                      </blockquote>
                    </div>
                  )}
                
              </div>
            )}
          </div>
          {verifiedCode
          && (
          <div>
            <div className="account-subheading -slim-padding">
              Enter your email address to claim.
            </div>
            <div className="form-container">
              <div className="form-section">
                <div className="form-columns">
                  <div className="form-column">
                    <input
                      className="input-block input-edrop-claim"
                      id="emailAddress"
                      type="text"
                      placeholder="your@email.com"
                      value={emailAddress}
                      onChange={(e) => handleEmailChange(e)}
                      onKeyUp={(e) => onEnterKey(e)} />
                  </div>
                  <div className="form-column">
                    <button
                      type="submit"
                      onClick={(e) => checkEmailAddress(e)}>
                      Claim Now!
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-section">
                <input
                    id="registerAcceptTerms"
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)} />
                <label className="label-inline" htmlFor="registerAcceptTerms">
                  I agree to the
                  {' '}
                  <a href="https://www.ethosnft.com/terms" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
                  .
                </label>
              </div>
            </div>

          </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default EDropLanding;
