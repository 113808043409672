import React, { Component } from 'react';
import SlimHero from '../components/hero/slimHero';
import HeathAboutEdrop from '../components/edrop/heathAboutEdrop';
import AllureAboutEdrop from '../components/edrop/allureAboutEdrop';

class EDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.body.classList.add('-account-background');
    document.body.style.backgroundImage = 'url(' + process.env.ACCOUNT_BACKGROUND + ')';
  }

  componentWillUnmount() {
    document.title = process.env.SITE_NAME;
    document.body.classList.remove('-account-background');
    document.body.style.backgroundImage = 'none';
  }

  render() {
    const { nftToken } = this.state;
    return (
      <div className="account-container__outer">
        <div className="account-container__inner">
          <SlimHero />
          <AllureAboutEdrop />
        </div>
      </div>
    );
  }
}

export default EDrop;
