import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function HeathAboutEdrop() {
  // const [questions, setQuestions] = useState([]);
  
  return (
    <div className="about-edrop__inner">
      <div className="account-box">
        <div className="about-edrop__section">
          <div className="about-edrop__hdr">THE HEATH CLUB TOKEN</div>
          <div className="about-edrop__p">Introducing the Heath Club Token, the non-fungible token (NFT) initiative designed to engage with its community of VIP advocates and superfans.</div>
          <div className="about-edrop__p">The Heath Club Token unlocks the benefit to collaborate on new launches, overnight stays, partner rewards, third party discounts and free products.</div>
        </div>
        <div className="about-edrop__section">
          <div className="about-edrop__hdr">REDEEM YOUR HEATH CLUB TOKEN</div>
          <div className="about-edrop__p">When you redeem your Heath Club token, it will sit within your ethos account. By owning this token, you will unlock benefits and rewards that will be shared with you soon.</div>
          {process.env.CREATOR_ID === 'ae6365f6-b510-4114-bc9b-8ed1c431960e'
            ? (
              <Link
                        to="/edrop/?nftToken=41b5f870-d52b-4969-9891-253debe505a0"
                        className="button">
                Activate your membership
              </Link>
            )
            : (
              <Link
                    to="/edrop/"
                    className="button">
                Activate your membership
              </Link>
            )}
        </div>
        <div className="about-edrop__section">
          <div className="about-edrop__hdr">WIN AN EXCLUSIVE FOUNDER’S TOKEN</div>
          <div className="about-edrop__p">When you redeem your Heath Club Token, you will automatically enter a competition to win one of the 15 exclusive Founder’s Tokens.</div>
          <div className="about-edrop__p">Within the Founder’s Token portfolio, there are 12 base level tokens and 3 gold-standard tokens. Each will unlock unique benefits and rewards related to wellness, superior products, fashion & lifestyle. </div>
          <div className="about-edrop__p">The benefits will drop soon and your rewards will be communicated on the Heath Twitter @HeathSkincare. </div>
        </div>
        <div className="about-edrop__section">
          <div className="about-edrop__hdr">What is an NFT?</div>
          <div className="about-edrop__p">An NFT is a digital token that lives on the blockchain. If you have the digital token, you will access exclusive benefits that only a holder gets, almost like a membership. However, unlike most memberships, you own an NFT and you can transfer it to others.</div>
        </div>
        <video autoPlay="autoplay" playsInline loop poster="https://ipfs.ethosnft.com/heath/cgi-film-2023-poster.png">
          <source src="https://ipfs.ethosnft.com/heath/cgi-film-2023.mp4" type="video/mp4" />
          <track
                default
                kind="captions"
                srcLang="en"
                src="" />
          Your browser does not support the video tag.
        </video>
        <div className="about-edrop__section">
          <div className="about-edrop__p">“Ever since its conception, Heath has always been more than just a skincare brand. Our core values lie in community, collaboration with our loyal brand users and wellness. That’s why we have stepped into the world of Web3, using cutting-edge technology to give our most loyal community something special.”</div>
          <div className="about-edrop__p">Welcome to the next level-level Heath Club!</div>
          <div className="about-edrop__p">&bull; Harry Aaronson, co-founder Heath Skincare.</div>
        </div>
      </div>
      {/* <div className="about-edrop__icons">
        <a href="https://www.instagram.com/heath.skincare/" target="_blank" rel="noreferrer"><img src="https://ipfs.ethosnft.com/heath/instagram-logo-inverse.svg" width="50" alt="Instagram" /></a>
        <a href="https://twitter.com/HeathSkincare" target="_blank" rel="noreferrer"><img src="https://ipfs.ethosnft.com/heath/twitter-logo-inverse.svg" width="50" alt="Twitter" /></a>
      </div> */}
    </div>
  );
}

export default HeathAboutEdrop;
