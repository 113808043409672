import React, { Component } from 'react';
import SlimHero from '../components/hero/slimHero';
import EDropLanding from '../components/transfer/edropLanding';
import { getParameterByName } from '../managers/Helpers';
import AccountManager from '../managers/Account';
import TransferChecker from '../components/transfer/transferChecker';

class EDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorData: {},
      transferToken: null,
      transferCookie: null,
    };
  }

  componentDidMount() {
    document.title = process.env.CREATOR_SUBDOMAIN === 'account' ? 'ethos | Claim Digital Asset' : process.env.CREATOR_SUBDOMAIN + ' | Claim Digital Asset';
    if (getParameterByName('nftToken', window.location.href)) {
      // protec against duplicate tokens
      Object.entries(sessionStorage).map((obj) => {
        const key = obj[0];
        if (key.includes('NFT_TRANSFER')) {
          sessionStorage.removeItem(key);
        }
        return true;
      });
      // force the one in the URL to be in memory
      this.setState({
        transferCookie: 'NFT_TRANSFER_' + getParameterByName('nftToken', window.location.href),
        transferToken: getParameterByName('nftToken', window.location.href),
      });
      sessionStorage.setItem('NFT_TRANSFER_' + getParameterByName('nftToken', window.location.href), getParameterByName('nftToken', window.location.href));
    }
  }

  componentWillUnmount() {
    document.title = process.env.SITE_NAME;
  }

  render() {
    const { transferCookie, transferToken } = this.state;
    return (
      <div>
        <SlimHero />
        {transferToken && AccountManager.isLoggedIn()
          ? (
            <TransferChecker
              isAuthed={AccountManager.isLoggedIn()}
              transferToken={transferToken} />
          )
          : <EDropLanding transferCookie={transferCookie} transferToken={transferToken} />}
      </div>
    );
  }
}

export default EDrop;
