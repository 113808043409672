import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleModal from '../modal/modal';
import AccountManager from '../../managers/Account';

class TransferToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const {
      nft, onClose, onTransferToUser, onTransferToWallet,
    } = this.props;
    return (
      <div>
        <SimpleModal isOpen onBeforeClose={() => onClose()}>
          <div className="account-heading">Transfer Digital Asset</div>
          {AccountManager.isVerified()
            ? (
              <div className="transfer-toggle">
                <div className="transfer-toggle__item">
                  <h3>Transfer to another user?</h3>
                  <p>Use this feature to transfer this digital asset to another user on ethos. If this user is not on the ethos multiverse platform, they will receive an invite.</p>
                  <button onClick={() => onTransferToUser()} className="btn -block" type="button">Transfer to another user</button>
                </div>
                <div className="transfer-toggle__item">
                  <h3>Transfer to your wallet?</h3>
                  <p>Use this feature to transfer this digital asset to your wallet. Transferring your digital asset to a wallet will remove it from the ethos multiverse. Some features may no longer available for this digital asset.</p>
                  <button onClick={() => onTransferToWallet()} className="btn -block" type="button">Transfer to your wallet</button>
                </div>
                {/* <div className="transfer-toggle__item">
              <h3>Transfer to your Apple Wallet</h3>
              <p>Use this feature to transfer this NFT to your Apple wallet.</p>
              <button className="button button-small button-confirm" type="button">Transer to Apple wallet</button>
            </div> */}
              </div>
            )
            : (
              <div className="transfer-toggle">
                <div className="transfer-toggle__item">
                  <p>In order to transfer this asset to another user, you must have a verified account. Click the button below to complete your registration now.</p>
                  <Link className="button btn -block" to="/account/details">Verify account now!</Link>
                  {/* <button onClick={() => onTransferToUser()} className="btn -block" type="button">Verify account now!</button> */}
                </div>
              </div>
            )}
        </SimpleModal>
      </div>
    );
  }
}

TransferToggle.propTypes = {
  // nft: PropTypes.string,
  onClose: PropTypes.func,
  onTransferToUser: propTypes.func,
  onTransferToWallet: propTypes.func,
  // onSuccess: PropTypes.func,
};

export default TransferToggle;
