import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';
import BrandWidget from './brandWidget';
import UtilityList from './utilityList';
import AssetInformation from './assetInformation';
import AssetFAQ from './assetFAQ';
import BonLookFAQ from './bonlookFAQ';
import RefCheckFAQ from './refcheckFAQ';
import SimpleModal from '../modal/modal';
import TransferToggle from '../account/transferToggle';
import TransferNFT from '../account/transferNFT';
import TransferWallet from '../account/transferWallet';
import UtilityManager from '../../managers/Utility';
import {
  getScanUrl,
} from '../../managers/Helpers';

function AssetDetails() {
  // State variables using useState
  // const [openNav, setNavstate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [asset, setAsset] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [showPassModal, setShowPassModal] = useState(false);
  const [isPassGenerating, setIsPassGenerating] = useState(true);
  const [nftPass, setNftPass] = useState(null);
  const [openNav, setNavstate] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [transferSelector, toggleTransferSelectorModal] = useState(false);
  const [transfer, setUserTransferModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isModalLoading, setModalLoadingState] = useState(false);
  const [walletTransfer, setWalletTransferModal] = useState(false);
  const [sortedUtility, setSortedUtility] = useState([]);

  const { nftId } = useParams();

  const parseUtility = (asset) => {
    const allUtility = asset.collectionUtility.concat(asset.nftUtility);
    const categorizedUtility = UtilityManager.categorizeAssetUtility(allUtility);
    console.log('=== parseUtility ===');
    console.log(categorizedUtility);
    setSortedUtility(categorizedUtility);
  };

  const getDetails = async (nftId) => {
    // get the collection from the server
    setIsLoading(true);
    // TODO: need to handle this better for anonymous users
    const accountData = await AccountManager.get(AccountManager.getToken());
    if (accountData) {
      setAccountDetails(accountData);
    }
    AccountManager.getNftDetails(AccountManager.getToken(), nftId).then((res) => {
      setAsset(res.nft);
      parseUtility(res.nft);
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      toast.error('Oops. There was a problem getting your collection data. Please try again later.');
    });
  };

  const toggleTransferModal = (showModal, nft) => {
    setNavstate(false);
    toggleTransferSelectorModal(showModal);
    setSelectedFile(asset);
  };

  const showUserTransferModal = () => {
    toggleTransferSelectorModal(false);
    setUserTransferModal(asset);
  };

  const showWalletTransferModal = () => {
    toggleTransferSelectorModal(false);
    setWalletTransferModal(asset);
  };

  const closeWalletTransferModal = (doUpdate) => {
    setWalletTransferModal(false);
    if (doUpdate) {
      // getCollection();
    }
  };

  const transferSuccessful = (walletAddress) => {
    console.log(walletAddress);
    console.log('NFT transferred successfully');
    nft.withdrawAddress = walletAddress;
  };

  const confirmTransfer = () => {
    asset.hasPendingTransfer = true;
    setUserTransferModal(false);
  };

  const cancelTransfer = (newItem) => {
    setShowModal(true);
  };

  const confirmCancel = async () => {
    asset.hasPendingTransfer = false;
    setModalLoadingState(true);

    const payload = {
      nftId: asset.nftId,
      creatorId: process.env.CREATOR_ID,
    };

    const cancelled = await AccountManager.cancelTransfer(AccountManager.getToken(), payload);

    if (cancelled && cancelled.success) {
      toast.success('Successfully cancelled transfer.');
    } else {
      toast.error('Oops. Something went wrong. Please try again later.');
    }
    setModalLoadingState(false);
    setShowModal(false);
    // updateCollection();
  };

  const togglePassModal = async (nft) => {
    setShowPassModal(!showPassModal);
    setIsPassGenerating(true);
    const payload = {
      nftId: nft.nftId,
    };
    const applePass = await AccountManager.generateApplePass(AccountManager.getToken(), payload).then((response) => {
      console.log('=== generating apple pass ===');
      console.log(response);
      if (response && response.success) {
        toast.success('Successfully created Apple pass.');
        setNftPass(response.passUri);
      } else {
        toast.error('Error creating Apple pass. Please try again later.');
        setShowPassModal(false);
      }
      setIsPassGenerating(false);
    });
  };

  const toggleNav = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (openNav) {
      setNavstate(false);
    } else {
      setNavstate(true);
    }
  };

  useEffect(() => {
    getDetails(nftId);
    console.log('=== details page ===');
    console.log(nftId);
    if (!AccountManager.isLoggedIn()) {
      window.location = '/login';
    }
  }, []);

  return (
    <div className="digital-asset-details">
      {walletTransfer
      && (
        <div>
          <TransferWallet
            nft={walletTransfer}
            onClose={(doUpdate) => closeWalletTransferModal(doUpdate)}
            onSuccess={(walletAddress) => transferSuccessful(walletAddress)} />
        </div>
      )}
      {transfer
        && (
        <div>
          <TransferNFT nft={transfer} onClose={() => setUserTransferModal(false)} onSuccess={() => confirmTransfer()} />
        </div>
        )}
      {transferSelector
        && (
        <div>
          <TransferToggle
            onTransferToUser={() => showUserTransferModal()}
            onTransferToWallet={() => showWalletTransferModal()}
            onClose={() => toggleTransferSelectorModal(false)} />
        </div>
        )}
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        {isModalLoading
          && <Throbber throbberText="Cancelling transfer! Please stand by..." />}
        Are you sure you want to cancel this transfer?
        <div className="form-cta">
          <button
            className="button button-small button-confirm -no-margin"
            type="submit"
            onClick={(e) => confirmCancel(e)}>
            Confirm Cancel
          </button>
          &nbsp;
          <button
            className="button button-small -no-margin"
            type="submit"
            onClick={() => setShowModal(false)}>
            Back
          </button>
        </div>
      </SimpleModal>
      <SimpleModal isOpen={showPassModal} onBeforeClose={() => setShowPassModal(false)}>
        {showPassModal && (
        <div>
          {isPassGenerating
              && <Throbber throbberText="Generating Apple Pass! Please stand by..." />}
          <div className="transfer-success register-results">
            <img className="register-results__icon" src="https://ipfs.ethosnft.com/ethos/icon-success.svg" alt="" />
            <div className="register-results__header">Success!</div>
            <p>
              Apple pass for this NFT has been created. Click the Add to wallet button below to add this to your wallet.
            </p>
          </div>
          <div className="form-cta -align-center">
            <a href={nftPass} className="button button-apple-wallet">
              <img src="http://ipfs.ethosnft.com/ethos/button-apple-wallet.png" alt="Add to Apple Wallet" />
            </a>
          </div>
        </div>
        )}
      </SimpleModal>
      {isLoading
        && <Throbber throbberText="Loading digital asset details! Please stand by..." />}
      {asset
      && (
        <>
          <div className="collection-breadcrumb">
            My Collection&nbsp;&nbsp;&bull;&nbsp;&nbsp;
            <Link to="/account/collection">{asset.creator.creatorName}</Link>
                &nbsp;&nbsp;&bull;&nbsp;&nbsp;
            <Link to={'/asset/' + asset.nftId}>{asset.assetVariation ? asset.assetVariation.variationName : asset.name}</Link>
          </div>
          <h1 className="digital-asset__header">
            {asset.assetVariation ? asset.assetVariation.variationName : asset.name}
            <div className="digital-asset__header-utils">
              {asset.passTemplate
              && <button type="button" className="button-collection" onClick={() => togglePassModal(asset)}>Add to Wallet</button>}
              &nbsp;
              <button onClick={(e) => toggleNav(e)} type="button" className={openNav ? 'button-collection__inverse open' : 'button-collection__inverse'}>&#x22EF;</button>
              <div className={openNav ? 'dropdown-widget__menu -asset-details open' : 'dropdown-widget__menu -asset-details'}>
                {AccountManager.isVerified()
                  ? (
                    <div>
                      {asset && asset.hasPendingTransfer
                    && (
                      <div className="dropdown-widget__menu--item">
                        <a onClick={() => cancelTransfer(asset)} href="#">Cancel transfer</a>
                      </div>
                    )}
                      {!asset.withdrawAddress && !asset.hasPendingTransfer
                    && (
                      <div className="dropdown-widget__menu--item">
                        <a onClick={() => toggleTransferModal(true)} href="#">Transfer Digital Asset</a>
                      </div>
                    )}
                    </div>
                  )
                  : (
                    <div>
                      {!asset.withdrawAddress && !asset.hasPendingTransfer
                    && (
                      <div className="dropdown-widget__menu--item">
                        <a onClick={() => toggleTransferModal(true)} href="#">Transfer Digital Asset</a>
                      </div>
                    )}
                    </div>
                  )}
                {asset.contractAddress
                && (
                <div className="dropdown-widget__menu--item">
                  <a href={getScanUrl(asset)} target="_blank" onClick={() => setNavstate(false)} rel="noreferrer">View on Blockchain</a>
                </div>
                )}
              </div>
            </div>
          </h1>
          <div className="digital-asset__media">
            <div className="digital-assets__tile--image-holder">
              <img src={asset.imageUrl} alt="Digital asset thumbnail" />
            </div>
          </div>
          <div className="digital-asset__details">
            <div>
              <div className="digital-asset__section">
                <h2 className="digital-asset__subheader">Description</h2>
                {asset.asset.assetDescription !== 'NULL'
                && (
                <div className="digital-asset__details--text">
                  {asset.asset.assetDescription}
                </div>
                )}
                <div
                className="digital-asset__details--text"
                dangerouslySetInnerHTML={{ __html: asset.asset.assetHTMLDesc }}>
                </div>
              </div>
              {sortedUtility.length > 0
              && (
              <div>
                {sortedUtility.map((utility) => {
                  if (utility.label !== 'Media') {
                    return (
                      <div className="utility-section" key={utility.label}>
                        <h2 className="digital-asset__subheader">{utility.label}</h2>
                        <UtilityList
                          noHeader
                          nftId={asset.nftId}
                          assetUtility={[]}
                          collectionUtility={utility.utility} />
                      </div>
                    );
                  }
                  return <div key={utility.label}></div>;
                })}
              </div>
              )}
              {sortedUtility.length > 0
              && (
              <div>
                {sortedUtility.map((utility) => (
                  <div key={utility.label}>
                    {utility.label === 'Media'
                    && (
                    <div className="utility-section">
                      <h2 className="digital-asset__subheader -extra-padding">{utility.label}</h2>
                      <UtilityList
                        slideshow
                        noHeader
                        nftId={asset.nftId}
                        assetUtility={[]}
                        collectionUtility={utility.utility} />
                    </div>
                    )}
                  </div>
                ))}
              </div>
              )}
              <div>

              </div>
            </div>
            <BrandWidget creatorId={process.env.CREATOR_ID} />
          </div>
          <AssetFAQ creatorId={process.env.creatorId} />
          <AssetInformation asset={asset} />
        </>
      )}

    </div>
  );
}

export default AssetDetails;
