/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import { getUtilityType } from '../../managers/Helpers';
import AccountManager from '../../managers/Account';
import CreatorManager from '../../managers/Creator';
import SimpleModal from '../modal/modal';
import Throbber from '../throbber';
import AzureMediaPlayer from '../video/azureMediaPlayer';
import Gallery from '../account/gallery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function UtilityList({
  slideshow, layout, noHeader, nftId, assetUtility, collectionUtility, onPlay,
}) {
  const [formattedUtilities, setFormattedUtilities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileViewer, setFileViewerState] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const generateCommerceUrl = async (gatedCommerceId, e) => {
    e.preventDefault();
    setShowModal(true);
    const generatedCommerceUrl = await CreatorManager.generateGatedCommerceUrl(gatedCommerceId, nftId, AccountManager.getToken());
    if (generatedCommerceUrl && generatedCommerceUrl.success) {
      window.open(generatedCommerceUrl.draftOrderUrl, '_blank');
      setShowModal(false);
    } else {
      toast.error(generatedCommerceUrl.message || 'Oops. Something went wring generating your link. Please try again later.');
      setShowModal(false);
    }
  };

  const formatUtilities = (assetUtility, collectionUtility) => {
    setFormattedUtilities(assetUtility.concat(collectionUtility));
  };

  useEffect(() => {
    formatUtilities(assetUtility, collectionUtility);
  }, [assetUtility, collectionUtility]);

  const closeFileViewer = () => {
    setFileViewerState(false);
    setSelectedVideo(null);
    setSelectedFile(null);
  };

  const loadAsset = async (assetUrl, assetType) => {
    setFileViewerState(true);
    if (assetType === 'image') {
      setSelectedFile(assetUrl);
    } else {
      setSelectedVideo(assetUrl);
    }
  };

  const getGatedAsset = async (gatedContent, e) => {
    e.preventDefault();
    setShowModal(true);
    setTimeout(async () => {
      const gatedContentFile = await AccountManager.getGatedContent(AccountManager.getToken(), gatedContent.gatedContentId);
      setShowModal(false);
      if (gatedContentFile && gatedContentFile.success) {
        if (gatedContent.contentType === 1) {
          loadAsset(gatedContentFile.fileUrl, 'image');
        } else {
          loadAsset(gatedContentFile.fileUrl, 'video');
        }
      } else {
        toast.error('Oops. Something went wrong. Please try again later.');
      }
    }, 3000);
  };

  const copyToClipboard = (voucher, e) => {
    e.preventDefault();
    navigator.clipboard.writeText(voucher.voucherCode);
    toast.success('Voucher has been copied to clipboard.');
  };

  useEffect(() => {
    console.log('=== combined utility ===');
    console.log(formattedUtilities);
  }, [formattedUtilities]);

  return (
    <div className="utility-list">
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        <Throbber throbberText="Checking for gate access.. Stand by." />
      </SimpleModal>
      {showFileViewer
        && (
        <div className="file-viewer">
          <div className={selectedVideo ? 'file-viewer__inner -video' : 'file-viewer__inner'}>
            <a className="modal__close -outside-right" href="#" onClick={() => closeFileViewer()}>
              <img src="https://ipfs.ethosnft.com/global/icon-close-dark.svg" alt="Close modal" />
            </a>

            {selectedFile
            && (
              // <Gallery creator={selectedFile} />
              <div className="file-viewer__scroller">
                <div className="file-viewer__gallery"><img src={selectedFile} alt="" /></div>
              </div>
            )}

            {selectedVideo
            && <AzureMediaPlayer src={selectedVideo} />}

            {/* <video data-setup='{"nativeControlsForTouch": false}' className="azuremediaplayer amp-default-skin" controls controlslist="nodownload" autoPlay="autoplay" loop muted>
              <source src={selectedVideo} type="video/mp4" />
              <track
                default
                kind="captions"
                srcLang="en"
                src="" />
              Your browser does not support the video tag.
            </video> */}

          </div>
        </div>
        )}
      {!noHeader
        && <h2 className="digital-asset__subheader">Benefits</h2>}

      {slideshow && formattedUtilities.length > 0
        && (
          <div className="utility-container -video-carousel">
            <Slider {...settings}>
              {formattedUtilities.map((utility) => (
                <div className="utility-item__slide" key={utility.utilityId}>
                  {getUtilityType(utility.utilityType) === 'Video Content'
              && (
                <div className="utility-item__slide--inner">
                  <div
                    className="utility-item__slide--image"
                    style={{
                      backgroundImage: 'url(' + utility.gatedContents[0].thumbnail + ')',
                    }}>
                    {/* <a href="#" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>View media</a> */}
                  </div>
                  <div className="utility-item__slide--title" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>{utility.utilityDescription}</div>
                  <div className="utility-item__slide--description" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>{utility.gatedContents[0].description}</div>
                </div>
              )}
                </div>
              ))}
            </Slider>
          </div>
        )}
      {!slideshow
      && (
      <div className={layout === 'flex' ? 'utility-container -flex' : 'utility-container'}>
        { formattedUtilities.length > 0
          && formattedUtilities.map((utility) => (
            <div className="utility-item" key={utility.utilityId}>
              {/* {getUtilityType(utility.utilityType)} */}
              {getUtilityType(utility.utilityType) === 'Coupon Voucher'
              && (
                <div>
                  <div
                    className="utility-item__image"
                    style={{
                      backgroundImage: 'url(' + utility.vouchers[0].voucherImageUrl + ')',
                    }}>
                  </div>
                  <div className="utility-item__title">{utility.utilityDescription}</div>
                  <div className="utility-item__description">{utility.vouchers[0].description}</div>
                  <div className="utility-item__voucher-slot">
                    <input className="utility-item__voucher-input" type="text" readOnly value={utility.vouchers[0].voucherCode} />
                    <button
                      type="button"
                      className="utility-item__voucher-button"
                      onClick={(e) => copyToClipboard(utility.vouchers[0], e)}>
                      {process.env.CUSTOM_COPY_ICON
                        ? <img src={process.env.CUSTOM_COPY_ICON} width="14" alt="copy" />
                        : <img src="https://ipfs.ethosnft.com/ethos/ui/icon-copy.svg" width="14" alt="copy" />}
                      
                    </button>
                  </div>
                </div>
              )}
              {getUtilityType(utility.utilityType) === 'Generic Utility'
              && (
                <div>
                  <div
                    className="utility-item__image"
                    style={{
                      backgroundImage: 'url(' + utility.genericPromotions[0].thumbnailUrl + ')',
                    }}>
                  </div>
                  <div className="utility-item__title">{utility.utilityDescription}</div>
                  <div className="utility-item__description">{utility.genericPromotions[0].description}</div>
                  {utility.genericPromotions[0].linkUrl
                  && (
                  <div className="utility-item__cta">
                    <a href={utility.genericPromotions[0].linkUrl} className="button-collection" target="_blank" rel="noreferrer">{utility.genericPromotions[0].linkLabel}</a>
                  </div>
                  )}
                </div>
              )}
              {getUtilityType(utility.utilityType) === 'Video Content'
              && (
                <div>
                  <div
                    className="utility-item__image"
                    onClick={(e) => getGatedAsset(gate.gatedContentId, e)}
                    style={{
                      backgroundImage: 'url(' + utility.gatedContents[0].thumbnail + ')',
                    }}>
                  </div>
                  <div className="utility-item__title" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>{utility.utilityDescription}</div>
                  <div className="utility-item__description">{utility.gatedContents[0].description}</div>
                  <div className="utility-item__cta">
                    {utility.gatedContents[0].contentType === 1
                      ? <a href="#" className="button-collection" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>View images</a>
                      : <a href="#" className="button-collection" onClick={(e) => getGatedAsset(utility.gatedContents[0], e)}>Watch now</a>}
                  </div>
                </div>
              )}
              {getUtilityType(utility.utilityType) === 'Gated Commerce'
              && (
                <div>
                  <div
                    className="utility-item__image"
                    style={{
                      backgroundImage: 'url(' + utility.gatedCommerces[0].productThumbnail + ')',
                    }}>
                  </div>
                  <div className="utility-item__title">{utility.utilityDescription}</div>
                  <div className="utility-item__description">{utility.gatedCommerces[0].ecommerceProductDescription}</div>
                  {utility.gatedCommerces[0].contentType === 1 && utility.gatedCommerces[0].ecommerceProductUrl
                    && (
                      <div className="utility-item__cta">
                        <a className="button-collection" href={utility.gatedCommerces[0].ecommerceProductUrl} target="_blank" rel="noreferrer">Claim now</a>
                      </div>
                    )}
                  {utility.gatedCommerces[0].contentType === 2
                    && (
                      <div className="utility-item__cta">
                        <a onClick={(e) => generateCommerceUrl(utility.gatedCommerces[0].gatedCommerceId, e)} href="#" className="button-collection">Claim now</a>
                      </div>
                    )}
                </div>
              )}
            </div>
          ))}
      </div>
      )}
    </div>
  );
}

export default UtilityList;
